import React, {useEffect} from "react";
import {
  Box,
  Button,
  FormField,
  Header,
  Select,
  SelectProps,
  SpaceBetween,
} from "@amzn/awsui-components-react-v3";
import {AceTechnology} from "../../../utils/constants";
import {getCycleDutyValues, getEirpPsdValues} from "./ace-utils";
import constants from "../../../constants";
import {addLimitsSectionAction} from "../../../redux/actions/ace-action";
import {useDispatch, useSelector} from "react-redux";

type ValidationState = {
  isValid: boolean;
  cycleDutyMessage?: string;
  eirpPsdMessage?: string;
}

function AceRadiatedTestingLimits(props) {
  const dispatch = useDispatch();
  const aceReducer = useSelector((state: any) => state.aceReducer);

  const aceId = props.aceId;
  const [showInputs, setShowInputs] = React.useState(false);
  const [cycleDuty, setCycleDuty] = React.useState<SelectProps.Option | null>(null);
  const [eirpPsd, setEirpPsd] = React.useState<SelectProps.Option | null>(null);

  const [validationState, setValidationState] = React.useState<ValidationState>({
    isValid: true,
  });

  useEffect(() => {
    if (props.selectedTechnologies.includes(AceTechnology.Wifi6E)) {
      setShowInputs(true);
    } else {
      setShowInputs(false);
    }
  }, [props.selectedTechnologies]);

  const validateInputs = () => {
    let isValid = true;
    const validationState: ValidationState = {
      isValid: true
    };
    if (showInputs && !cycleDuty) {
      validationState["cycleDutyMessage"] = "Cycle duty cannot be empty"
      isValid = false;
    }
    if (showInputs && !eirpPsd) {
      validationState["eirpPsdMessage"] = "EIRP PSD cannot be empty"
      isValid = false;
    }
    validationState["isValid"] = isValid;
    setValidationState(validationState)
    return isValid;
  }

  const onCreateLimitsTableButtonClick = () => {
    const isValid = validateInputs();
    if (isValid) {
      dispatch(addLimitsSectionAction(aceId, cycleDuty?.["value"], eirpPsd?.["value"]));
    }
  }

  const clearValidationState = () => {
    setValidationState({
      isValid: true,
      cycleDutyMessage: "",
      eirpPsdMessage: ""
    })
  }

  return (
    <div>
      <Header
        variant="h2"
      >
        Radiated testing limits
      </Header>
      <SpaceBetween
        size="s"
        direction="vertical"
      >
        {showInputs &&
            <FormField
                label="Cycle duty"
                errorText={validationState?.cycleDutyMessage}
            >
                <Select
                    selectedOption={cycleDuty}
                    onChange={({detail}) => {
                      clearValidationState();
                      setCycleDuty(detail.selectedOption)
                    }}
                    options={getCycleDutyValues()}
                    placeholder="Select cycle duty value"
                />
            </FormField>
        }
        {showInputs &&
            <FormField
                label="EIRP PSD"
                errorText={validationState?.eirpPsdMessage}
            >
                <Select
                    selectedOption={eirpPsd}
                    onChange={({detail}) => {
                      clearValidationState();
                      setEirpPsd(detail.selectedOption)
                    }}
                    options={getEirpPsdValues()}
                    placeholder="Select EIRP PSD value"
                />
            </FormField>
        }
        <Button
          variant="primary"
          onClick={() => onCreateLimitsTableButtonClick()}
          disabled={
            aceReducer.limitsSectionLoadingStatus == constants.LOADING_LOAD ||
            aceReducer.limitsSectionLoadingStatus == constants.LOADING_SUCCESS
          }
        >
          Add limits section
        </Button>
        {
          aceReducer.limitsSectionLoadingStatus == constants.LOADING_LOAD &&
            <Box
                color={"text-status-info"}
            >
                Please wait while we add the radiated testing limits section ...
            </Box>
        }
        {
          aceReducer.limitsSectionLoadingStatus == constants.LOADING_SUCCESS &&
            <Box
                color={"text-status-success"}
            >
                Radiated testing limits section added successfully
            </Box>
        }
        {
          aceReducer.limitsSectionLoadingStatus == constants.LOADING_FAIL &&
            <Box
                color={"text-status-error"}
            >
                Could not add radiated testing limits section. Please try again.
            </Box>
        }
      </SpaceBetween>
    </div>
  )
}

export default AceRadiatedTestingLimits;
